<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
  >
    <v-layout wrap>
      <div
          v-if="isEverythingReady"
          class="w-100"
      >
        <h1 class="page-title text-xs-left mb-3 w-100">Analytics</h1>
        <div class="analytics m-5">
          <div class="bookings-analytics mb-5">
            <div class="analytics-header">
              <p class="analytics-header__heading">Client cohort (initial session)</p>
              <dashboard-booking-filters
                  :filters="bookingFilters"
                  @applyBookingFilters="applyBookingFilters"
              />
              <!-- Date range filter -->
              <date-range-filter class="mr-3"
                                 :place-holder="'Ongoing session period'"
                                 :filter-id="'ongoing_session_period_filter'"
                                 :query-type="'external'"
                                 :column="'created_at'"
              >
              </date-range-filter>
              <!-- Ongoing Session Type Filters -->
              <multi-select-filters :filter-id="'dashboard_session_type_filter'"
                                    :filters="sessionTypeFilters"
                                    :placeholder="'Select Session Type'"
                                    class="mr-2"
                                    :column="'session_type_id'"
                                    :query-type="'external'"
              ></multi-select-filters>
              <!-- Ongoing session filter -->
              <template v-if="orgFilters.length > 0">
                <multi-select-filters :filter-id="'org_filters'"
                                      class="mr-2"
                                      :filters="orgFilters"
                                      :placeholder="'Select Org'"
                                      :column="'id'"
                                      :query-type="'relationship'"
                                      :relation-ship-name="'userVoucher.voucherCode.org'"
                ></multi-select-filters>

                <multi-select-filters :filter-id="'org_exclude_filters'"
                                      class="mr-2"
                                      :filters="orgFilters"
                                      :placeholder="'Exclude Org'"
                                      :column="'id'"
                                      :query-type="'relationship'"
                                      :relation-ship-name="'userVoucher.voucherCode.org'"
                                      :relation-ship-type="'doesNotHave'"
                                      :operator="'in'"
                ></multi-select-filters>
                <!-- Filter bookings according to these plans-->
                <template v-if="planFilters.length > 0">
                  <multi-select-filters :filter-id="'plan_filter'"
                                        class="mr-2"
                                        :filters="planFilters"
                                        :placeholder="'Select Plan'"
                                        :column="'id'"
                                        :query-type="'relationship'"
                                        :relation-ship-name="'userVoucher.voucherCode.plan'"
                  ></multi-select-filters>
                </template>
              </template>
              <!-- Filter those bookings which are booked by clients who registered on MOT from this UTM Campaign-->

              <multi-select-filters :filter-id="'booking_utm_campaign_filters'"
                                    :filters="bookingUTMCampaignFilters"
                                    :placeholder="'Select campaign'"
                                    :column="'utm_campaign'"
                                    :query-type="'relationship'"
                                    :operator="'in'"
                                    class="mr-2"
                                    :relation-ship-name="'client.clientMarketingData'"
              ></multi-select-filters>

              <!-- Filter those bookings which are booked by clients who registered on MOT from this UTM Source -->

              <multi-select-filters class="mr-2"
                                    :filter-id="'booking_utm_source_filter'"
                                    :filters="bookingUTMSourceFilters"
                                    :placeholder="'Select source'"
                                    :column="'utm_source'"
                                    :query-type="'relationship'"
                                    :operator="'in'"
                                    :relation-ship-name="'client.clientMarketingData'"
              ></multi-select-filters>

            </div>


            <div class="analytics-items">
              <template v-for="(item, key) in analytics.bookings">
                <div
                    :key="key"
                    class="analytics-items__item"
                >
                  <p class="analytics-items__item__item-title">{{ key }}</p>
                  <div class="analytics-items__item__item-details">
                    <template v-for="(v, k) in item">
                      <div
                          :key="k"
                          class="item-details__item-data"
                      >
                        <p class="item-details__item-data__item-value">{{ v }}</p>
                        <p class="item-details__item-data__item-description">{{ k }}</p>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="clients-analytics">
            <div class="analytics-header">
              <p class="analytics-header__heading">Active therapy clients</p>
              <dashboard-user-filters
                  :filters="userFilters"
                  @applyUserFilters="applyUserFilters"/>
            </div>
            <div class="analytics-items">
              <template v-for="(item, key) in analytics.users">
                <div
                    :key="key"
                    class="analytics-items__item"
                >
                  <div class="analytics-items__item__item-details">
                    <template v-for="(v, k) in item">
                      <div
                          :key="k"
                          class="item-details__item-data"
                      >
                        <p class="item-details__item-data__item-value">{{ v }}</p>
                        <p class="item-details__item-data__item-description">{{ k }}</p>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <h1 class="page-title text-xs-left mb-3 w-100">Time Watched</h1>
        <div class="analytics mx-5">
          <filters
              :filters="watchTimeFilters"
              :source="'dashboard-booking'"/>
          <div>
            <div class="therapists-analytics">
              <div class="analytics-header">
                <div class="col-6">
                  <p class="analytics-header__heading">Author</p>
                </div>
                <div class="col-6">
                  <p class="analytics-header__heading">Total Watch Time</p>
                </div>
              </div>
              <div class="analytics-items analytics-items--watch-time-items">
                <template v-for="therapist in analytics.therapists">
                  <div class="col-6">
                    <p>{{ therapist.profile_header }}</p>
                  </div>
                  <div class="col-6">
                    <p>
                      {{ therapist.watch_time }} {{ therapist.watch_time > 1 ? 'minutes' : 'minute' }}
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <h1 class="page-title text-xs-left mb-3 w-100">Psychologists without min availability</h1>
            <div class="col-6 analytics mx-5 border-2px scrollbar">
              <div id="content">
                <ul v-for="(therapist) in analytics.minAvailableTherapists" class="no_bullets_ul">
                  <li> {{ therapist.title }} {{ therapist.first_name }} {{ therapist.last_name }}</li>
                </ul>
              </div>
            </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment'
import Filters from "@/components/common/dataTable/filters/Filters";
import DashboardBookingFilters from "@/components/main/DashboardBookingFilters";
import DashboardUserFilters from "@/components/main/DashboardUserFilters";
import MultiSelectFilters from "@/components/common/dataTable/filters/MultiSelectFilters";
import DateRangeFilter from "@/components/common/dataTable/filters/DateRangeFilter";
import routeHelper from '@/helpers/app/routeHelper'


export default {
  name: "Dashboard",
  components: {
    Filters,
    DashboardBookingFilters,
    DashboardUserFilters,
    MultiSelectFilters,
    DateRangeFilter
  },
  data() {
    return {
      isEverythingReady: true,
      bookingFilters: [],
      minAvailableTherapists: [],
      userFilters: [],
      watchTimeFilters: [],
      orgFilters: [],
      sessionTypeFilters: [],
      analytics: {
        bookings: [],
        users: [],
        therapists: []
      },
      selectedBookingFilters: [
        {
          filter_id: 'booking_time_filter',
          name: 'Default (Last 60 days)',
          constraints: {
            column: 'created_at',
            operator: 'between',
            value1: moment().subtract(60, 'days'),
            value2: moment(),
            type: 'Date',
            is_multiple: false
          },
          default: true
        }
      ],
      selectedUserFilters: [
        {
          filter_id: 'booking_time_filter',
          name: 'Default (Last 60 days)',
          constraints: {
            column: 'created_at',
            operator: 'between',
            value1: moment().subtract(60, 'days'),
            value2: moment(),
            type: 'Date',
            is_multiple: false
          },
          default: true
        }
      ],
      selectedWatchTimeFilters: [
        {
          filter_id: 'watch_time_filter',
          name: 'Default (Last 60 days)',
          constraints: {
            column: 'created_at',
            operator: 'between',
            value1: moment().subtract(60, 'days'),
            value2: moment(),
            type: 'Date',
            is_multiple: false
          },
          default: true
        }
      ],
      bookingUTMSourceFilters: [],
      bookingUTMCampaignFilters:[],

    }
  },
  mounted() {
    this.$events.listen('filter-set', e => this.applyWatchTimeFilters(e))
  },
  created() {
    this.getDashboardFilters();
    this.getTherapistAvailability();
  },
  methods: {
    getDashboardFilters() {
      this.isEverythingReady = false
      this.$http.get(this.url + 'filters/dashboard', {
        params: {
          timezone: this.timezone
        }
      }).then(response => {
        this.bookingFilters = response.data.booking_filters;
        this.userFilters = response.data.user_filters;
        this.watchTimeFilters = response.data.watch_time_filters;
        this.orgFilters = response.data.org_filters;
        this.sessionTypeFilters = response.data.session_type_filters;
        this.bookingUTMSourceFilters = response.data.booking_utm_source_filters;
        this.bookingUTMCampaignFilters = response.data.booking_utm_campaign_filters;
        this.planFilters = response.data.plan_filters;
        this.isEverythingReady = true;
        this.getBookingAnalytics();
        this.getUserAnalytics();
        this.getTherapistsWatchTime();
      }).catch(error => {
        this.isEverythingReady = true
        this.$toastr('error', error.response.data.message, 'Error')
      })
    },
    getBookingAnalytics() {
      this.$http.get(this.url + 'dashboard/analytics/booking', {
        params: {
          filters: this.selectedBookingFilters,
          timezone: this.timezone
        }
      }).then(response => {
        this.analytics.bookings = response.data.analytics;
      }).catch(error => {
        this.$toastr('error', error.response.data.message, 'Error');
      })
    },
    getUserAnalytics() {
      this.$http.get(this.url + 'dashboard/analytics/user', {
        params: {
          date: this.userFilters.date,
          days: this.userFilters.allPast ? 'all' : this.userFilters.days,
          timezone: this.timezone
        }
      }).then(response => {
        this.analytics.users = response.data.analytics;
      }).catch(error => {
        this.$toastr('error', error.response.data.message, 'Error');
      })
    },
    getTherapistsWatchTime() {
      this.$http.get(this.url + 'dashboard/therapists/watch_time').then(response => {
        this.analytics.therapists = response.data.therapists;
      }).catch(error => {
        this.$toastr('error', error.response.data.message, 'Error');
      })
    },
    applyBookingFilters(param) {
      let index = this.selectedBookingFilters.findIndex(x => x.filter_id.toLowerCase() === param.filter_id.toLowerCase());
      if (index >= 0) {
        this.selectedBookingFilters.splice(index, 1);
      }
      this.selectedBookingFilters.push(param);
      this.getBookingAnalytics();
    },
    applyUserFilters(filters) {
      this.userFilters = filters;
      this.getUserAnalytics();
    },
    applyWatchTimeFilters(param) {
      let index = this.selectedWatchTimeFilters.findIndex(x => x.filter_id.toLowerCase() === param.filter_id.toLowerCase());
      if (index >= 0) {
        this.selectedWatchTimeFilters.splice(index, 1);
      }
      this.selectedWatchTimeFilters.push(param);
      this.getTherapistsWatchTime();
    },
    getTherapistAvailability(){
      this.$http.get(this.url + routeHelper.availableTherapistsApi).then(response => {
        this.analytics.minAvailableTherapists = response.data.available_therapists;
      }).catch(error => {
        this.$toastr('error', error.response.data.message, 'Error');
      })
    }
  }
}
</script>
